import React from 'react';
import * as ReactDOM from 'react-dom/client';
import ReactDOMServer from 'react-dom/server';
import components from './components';
import './global/index.scss';

const tryHydrateReactComponents = () => {
  // Initialise React if there are any React components.
  if (!document.querySelector('react-component')) {
    return;
  }

  window.React = React;
  (window as any).ReactDOM = ReactDOM;
  window.ReactDOMServer = ReactDOMServer;
  (global as any).Components = components;

  if (
    'ReactJsAsyncInit' in window &&
    typeof window.ReactJsAsyncInit === 'function'
  ) {
    window.ReactJsAsyncInit();
  }
};

tryHydrateReactComponents();
