import * as React from 'react';

const QuoteImageBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: "QuoteImageBlock-Chunk" */ './organisms/QuoteImageBlock/QuoteImageBlockWrapper'
    ),
);

const TechnicalDownloadSearch = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TechnicalDownloadSearch-Chunk" */ './organisms/TechnicalDownloadSearch/TechnicalDownloadSearchWrapper'
    ),
);

const PageTitle = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PageTitle-Chunk" */ './organisms/PageTitle/PageTitleWrapper'
    ),
);

const ImageGallery = React.lazy(
  () =>
    import(
      /* webpackChunkName: "PageTitle-Chunk" */ './organisms/ImageGallery/ImageGalleryWrapper'
    ),
);

const HeadlineAndTextBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: "HeadlineAndTextBlock-Chunk" */ './organisms/HeadlineAndTextBlock/HeadlineAndTextBlockWrapper'
    ),
);

const TextBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TextBlock-Chunk" */ './organisms/TextBlock/TextBlockWrapper'
    ),
);

const ContactGrid = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ContactGrid-Chunk" */ './organisms/ContactGrid/ContactGridWrapper'
    ),
);

const ContentCards = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ContentCards-Chunk" */ './organisms/ContentCards/ContentCardsWrapper'
    ),
);

const Footer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Footer-Chunk" */ './organisms/Footer/FooterWrapper'
    ),
);

const FullWidthMedia = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FullWidthMedia-Chunk" */ './organisms/FullWidthMedia/FullWidthMediaWrapper'
    ),
);

const Header = React.lazy(
  () =>
    import(/* webpackChunkName: "Header" */ './organisms/Header/HeaderWrapper'),
);

const MyDocuments = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MyDocuments-Chunk" */ './organisms/MyDocuments/MyDocumentsWrapper'
    ),
);

const DashboardBanner = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardBanner-Chunk" */ './organisms/DashboardBanner/DashboardBanner'
    ),
);

const HighlightModule = React.lazy(
  () =>
    import(
      /* webpackChunkName: "HighlightModule-Chunk" */ './organisms/HighlightModule/HighlightModuleWrapper'
    ),
);

// Back-End work not yet compelted on B2B
const StorytellingModule = React.lazy(
  () =>
    import(
      /* webpackChunkName: "StorytellingModule-Chunk" */ './organisms/StorytellingModule/StorytellingModuleWrapper'
    ),
);

const Breadcrumb = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Breadcrumb-Chunk" */ './organisms/Breadcrumb/BreadcrumbWrapper'
    ),
);

const BrandsAndSolutionsListing = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BrandsAndSolutionsListing-Chunk" */ './organisms/BrandsAndSolutionsListing/BrandsAndSolutionsListing'
    ),
);

const ServiceAccordion = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ServiceAccordion-Chunk" */ './organisms/ServiceAccordion/ServiceAccordionWrapper'
    ),
);

const HeadlineAccordion = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ServiceAccordion-Chunk" */ './organisms/HeadlineAccordion/HeadlineAccordion'
    ),
);

const IconCardsGrid = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ServiceAccordion-Chunk" */ './organisms/IconCardBlock/IconCardBlock'
    ),
);

const MediaHero = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MediaHero-Chunk" */ './organisms/MediaHero/MediaHeroWrapper'
    ),
);

const ImageCardBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ImageCardBlock-Chunk" */ './organisms/ImageCardBlock/ImageCardBlock'
    ),
);
// Use format <BE Component Name>: <FE Component Name> if the component names differ.

export default {
  BrandsAndSolutionsListing,
  Breadcrumb,
  ContactGrid,
  ContentCards,
  DashboardBanner,
  DocumentManagementBlock: MyDocuments,
  Footer,
  FullWidthMedia,
  Header,
  HeadlineAccordion,
  HeadlineAndTextBlock,
  HighlightModule,
  IconCardsGrid,
  ImageCardBlock,
  ImageGallery,
  MediaHero,
  PageTitle,
  QuoteImageBlock,
  ServiceAccordion,
  StorytellingModule,
  TechnicalDownloadSearch,
  TextBlock,
};
